/**
 * This page is only used to consume the jwt token and put it in localStorage. It then redirects to the appropriate
 * page based on the jwt.
 */
import { useRouter } from "next/router";
import { useEffect } from "react";
import { TOKEN_KEY } from "@hooks/useJwt.hook";

const login = () => {

    const router = useRouter();
    const { token } = router.query;

    useEffect(() => {
        if (!token) {
            return;
        }

        localStorage.removeItem(TOKEN_KEY);

        localStorage.setItem(TOKEN_KEY, token as string);

        router.push('/admin/locations');
    }, [router]);

    return "Loading...";
};

export default login;
